const allowNumberOnlyRegex = /^[0-9\b]+$/;
const emailValidatorRegex =
  /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:[.-][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/i;
const mobileNumberRegex = /^[0-9]{10}$/;
const noSpecialCharsAllowedRegex = /^[a-zA-Z0-9\s]+$/;
const empCodeRegex = /^[0-9]{1,4}$/
export {
  allowNumberOnlyRegex,
  emailValidatorRegex,
  mobileNumberRegex,
  noSpecialCharsAllowedRegex,
  empCodeRegex
};
